<template>
  <div class="mb-4">
    <div class="box">
      <div class="box-header text-h4 font-weight-bold">
        {{ title }}
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <form
        action=""
        @submit.prevent="submit"
        v-promise-btn="{ action: 'submit' }"
      >
        <div class="box-body">
          <div class="">
            <p v-if="label == 'O'">
              โปรดอธิบายว่า <strong>ปัจจัยภายนอก</strong> เรื่องใด
              มีแนวโน้มจะเป็นอย่างไร
              และจะช่วยให้ประสบความสำเร็จบรรลุเป้าหมาย<strong>ง่ายขึ้น</strong>ได้อย่างไร
            </p>
            <p v-if="label == 'T'">
              โปรดอธิบายว่า <strong>ปัจจัยภายนอก</strong> เรื่องใด
              มีแนวโน้มจะเป็นอย่างไร
              และ<strong>กีดขวางบั่นทอน</strong>ไม่ให้บรรลุเป้าหมายได้อย่างไร
            </p>
            <p v-if="label == 'S'">
              โปรดอธิบายว่า <strong>ปัจจัยภายใน</strong> เรื่องใด
              มีแนวโน้มจะเป็นอย่างไร
              และจะเอื้อให้บรรลุเป้าหมาย<strong>ง่ายขึ้น</strong>ได้อย่างไร
            </p>
            <p v-if="label == 'W'">
              โปรดอธิบายว่า <strong>ปัจจัยภายใน</strong> เรื่องใด
              มีแนวโน้มจะเป็นอย่างไร
              และจะ<strong>บั่นทอน</strong>ไม่ให้บรรลุเป้าหมายได้อย่างไร
            </p>
            <div
              class="form-group row"
              v-for="(swot, index) in swotForm.$v.swots.$each.$iter"
              :key="index"
            >
              <label class="col-form-label col-1 font-weight-bold"
                >{{ label }}{{ parseInt(index) + 1 }}.</label
              >
              <div class="col-10">
                <textarea
                  class="form-control"
                  v-model="swot.detail.$model"
                ></textarea>
                <div class="error text-danger" v-if="swot.detail.$dirty">
                  <div v-if="!swot.detail.required">โปรดระบุ</div>
                  <div v-if="!swot.detail.maxLength">
                    เกินจำนวนตัวอักษรที่กำหนด
                  </div>
                </div>
              </div>
              <div class="col-1">
                <button
                  type="button"
                  @click="removeSwot(index)"
                  class="btn btn-outline-red"
                >
                  ลบ
                </button>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <button class="btn btn-red" type="button" @click="addSwot">
                  <eva-icon name="plus-circle-outline"></eva-icon> เพิ่ม{{
                    title
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-footer">
          <div class="form-group text-right mt-3">
            <button type="submit" class="btn btn-action">อัพเดต</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from '../modules/form/form';
import {
  required,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import { nth, reduce, cloneDeep, get } from 'lodash';

export default {
  name: 'SwotForm',

  components: {},

  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    type: {
      type: Number,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    swots: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      swotForm: new Form({
        data: () => {
          return {
            type: this.type,
            target_id: this.target.id,
            swots: cloneDeep(this.swots) || [],
            config: {
              resetAfterSuccess: false,
              transformToPayload: (payload) => {
                payload.swots = payload.swots.filter((item) => {
                  return item.detail.trim() != '';
                });
                return payload;
              },
            },
          };
        },
        validations: {
          swots: {
            $each: {
              detail: {
                required,
                maxLength: maxLength(16000000),
              },
            },
          },
        },
      }),
    };
  },

  created() {},

  computed: {},

  methods: {
    addSwot() {
      this.swotForm.swots.push({
        detail: '',
      });
    },
    removeSwot(index) {
      this.swotForm.swots.splice(index, 1);
    },
    submit() {
      return this.swotForm
        .patch('/api/current_report/swots/update')
        .then((data) => {
          this.$store.commit('peno/updateSwots', {
            updatedSwots: data,
            targetId: this.target.id,
          });
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
