<template>
  <div class="swot-badge" :class="colorClass">
    {{ swot.badge }}
  </div>
</template>

<script>
export default {
  name: 'SwotBadge',

  props: {
    swot: {
      type: Object,
      required: true,
    },
  },

  computed: {
    colorClass() {
      let color = 'ipepp';
      switch (this.swot.type) {
        case 1: //Strength
          color = 'blue';
          break;
        case 2: //Weakness
          color = 'red';
          break;
        case 3: //Opportunities
          color = 'green';
          break;
        case 4: //Threat
          color = 'yellow';
          break;
      }
      return `text-${color} bg-lighter-${color}`;
    },
  },
};
</script>

<style scoped></style>
