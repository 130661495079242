Target
<template>
  <div class="mb-4">
    <div class="box">
      <div class="box-header text-h4 font-weight-bold">
        สร้างแนวทางที่ได้จากการวิเคราะห์ SWOT
        <button
          @click="$modal.hide(name)"
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <eva-icon name="close-outline"></eva-icon>
        </button>
      </div>
      <div class="box-body">
        <div class="">
          <div class="form-group row">
            <label class="col-form-label col-2 font-weight-bold"
              >เป้าหมาย</label
            >
            <div class="col-8">
              <!--<input-->
              <!--type="text"-->
              <!--class="form-control-plaintext"-->
              <!--placeholder=""-->
              <!--:value="target.title"-->
              <!--/>-->
              {{ target.title }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-2 font-weight-bold">SWOT</label>
            <div class="col-10">
              <div
                class="row mb-2"
                v-for="(swot, index) in credentials.$v.swots.$each.$iter"
                v-bind:key="index"
              >
                <div class="col-2">
                  <multiselect
                    v-model="swot.id.$model"
                    deselect-label=""
                    select-label=""
                    :searchable="false"
                    :options="orderedTargetSwotIds"
                    :allow-empty="false"
                    :preselect-first="true"
                    :show-labels="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      <div>{{ swotFromId(option).badge }}</div>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      <div>
                        <strong>{{ swotFromId(option).badge }}</strong> -
                        {{ swotFromId(option).detail }}
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="col-9">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="เหตุผล"
                    v-model="swot.pivot.cause.$model"
                  />
                  <div class="error text-danger" v-if="swot.pivot.cause.$dirty">
                    <div v-if="!swot.pivot.cause.required">โปรดระบุ</div>
                    <div v-if="!swot.pivot.cause.maxLength">
                      เกินจำนวนตัวอักษรที่กำหนด
                    </div>
                  </div>
                </div>
                <button
                  type="col-1 button"
                  @click="removeSwot(index)"
                  class="btn btn-outline-red"
                >
                  ลบ
                </button>
              </div>
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-red p-0 py-2" @click="addSwot">
                    <eva-icon
                      width="40px"
                      height="40px"
                      name="plus-square-outline"
                    ></eva-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold"
              >โปรดอธิบายแนวทางพัฒนาที่ได้จากการวิเคราะห์ SWOT</label
            >
            <textarea
              class="form-control"
              v-model="credentials.text"
            ></textarea>
            <div class="error text-danger" v-if="credentials.$v.text.$dirty">
              <div v-if="!credentials.$v.text.required">โปรดระบุ</div>
              <div v-if="!credentials.$v.text.maxLength">
                เกินจำนวนตัวอักษรที่กำหนด
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="form-group text-right mt-3">
          <button
            @click="deleteTargetStrategyAlert"
            class="btn btn-outline-red mr-4"
            v-if="targetStrategy"
          >
            ลบ
          </button>
          <button v-promise-btn @click="submit" class="btn btn-action">
            ยืนยัน
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../modules/form/form';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import {
  required,
  sameAs,
  maxValue,
  maxLength,
} from 'vuelidate/lib/validators';
import { nth, reduce, cloneDeep, get, map, find } from 'lodash';

export default {
  name: 'TargetStrategyForm',

  components: {
    Multiselect,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    targetStrategy: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      targetTypes: [
        {
          id: 1,
          title: 'RE',
        },
        {
          id: 2,
          title: 'EE',
        },
        {
          id: 3,
          title: 'อื่นๆ',
        },
      ],
      credentials: new Form({
        data: () => {
          return {
            target_id: this.target.id,
            id: get(this.targetStrategy, 'id', null),
            text: get(this.targetStrategy, 'text', null),
            swots: cloneDeep(get(this.targetStrategy, 'swots', null)) || [],
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {
          text: {
            required,
            maxLength: maxLength(16000000),
          },
          swots: {
            $each: {
              id: {
                required,
              },
              pivot: {
                cause: {
                  required,
                  maxLength: maxLength(65000),
                },
              },
            },
          },
        },
      }),
    };
  },

  created() {},

  computed: {
    orderedTargetSwot() {
      return this.target.swots
        .concat()
        .sort((a, b) => this.swotWeight(a) - this.swotWeight(b));
    },
    orderedTargetSwotIds() {
      return map(this.orderedTargetSwot, 'id');
    },
  },

  methods: {
    swotFromId(swotId) {
      return find(this.target.swots, { id: swotId });
    },
    swotWeight(swot) {
      // used for compare function of computed orderedTargetSwot (to order from O -> T -> S -> W)
      let value = 0;
      switch (swot.type) {
        case 1:
          value += 300;
          break;
        case 2:
          value += 400;
          break;
        case 3:
          value += 100;
          break;
        case 4:
          value += 200;
          break;
        default:
          value += 1000;
      }
      return value + swot.order;
    },
    addSwot() {
      this.credentials.swots.push({
        id: get(this.target.swots, '[0].id'),
        pivot: {
          cause: '',
        },
      });
    },
    removeSwot(index) {
      this.credentials.swots.splice(index, 1);
    },
    deleteTargetStrategyAlert() {
      this.$vDialog.alert(
        'ต้องการลบกลยุทธ์นี้',
        () => {
          window.axios
            .delete(
              `/api/current_report/target_strategies/${this.targetStrategy.id}`
            )
            .then((response) => {
              // this.$emit('onDeleted', this.targetStrategy);
              this.$store.commit(
                'peno/deleteTargetStrategy',
                this.targetStrategy
              );
              this.$modal.hide(this.name);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        {
          messageType: 'confirm',
          title: 'ยืนยัน',
          iconClassName: '',
          isCaution: true,
          cancelCallback: () => {},
        }
      );
    },
    submit() {
      return this.credentials
        .patch('/api/current_report/target_strategies/update')
        .then((data) => {
          this.$store.commit('peno/updateTargetStrategy', data);
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.multiselect__tags {
  min-height: 47px !important;
}
.multiselect__single {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
.multiselect__content-wrapper {
  width: unset;
  border-top: 1px solid #e8e8e8;
}
.multiselect__element {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
</style>
