<template>
  <section class="container-fluid mt-4">
    <div class="d-flex justify-content-between">
      <div>
        <router-link
          class="btn btn-red py-1 px-4 mr-3"
          :to="{ name: 'peno.targets' }"
        >
          <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
          ย้อนกลับ (เป้าหมายด้านพลังงาน)
        </router-link>
      </div>
      <div>
        <router-link
          class="btn btn-action py-1 px-4 mr-3"
          :to="{ name: 'peno.projects' }"
        >
          ไปต่อ (ออกแบบโครงการ)
          <eva-icon name="arrow-ios-forward" fill="currentColor"></eva-icon>
        </router-link>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-12 col-md-3">
        <div class="box mb-4">
          <div class="box-header">
            <div class="text-ipepp">เป้าหมายด้านพลังงานจังหวัด</div>
          </div>
          <div class="box-body d-flex flex-column px-0 pt-0">
            <div
              class="text-ipepp px-3 py-3 border-bottom pointer"
              @click="selectTarget(index, target.id)"
              v-for="(target, index) in targets"
              v-bind:key="target.id"
              :class="{ 'target-selected': index == targetSelectedIndex }"
            >
              <div class="font-weight-bold">
                เป้าหมายที่ {{ parseInt(index) + 1 }}
              </div>
              <div class="">
                {{ target.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="box">
          <div class="box-header d-flex justify-content-between">
            <div class="text-ipepp">
              วิเคราะห์ SWOT
              <span class="pointer text-blue" @click="showGuidingSwotModal()">
                <u>ดูคำแนะนำ</u>
              </span>
            </div>
          </div>
          <div class="box-body p-0">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6 border-right border-bottom py-2 px-3">
                  <div class="text-green mb-3">โอกาส</div>
                  <div
                    class="mb-2"
                    v-for="swot in selectedTargetOpportunitySwot"
                    v-bind:key="swot.id"
                  >
                    <span class="font-weight-bold">{{ swot.badge }}:</span>
                    {{ swot.detail }}
                  </div>
                  <div class="text-right mt-2">
                    <button
                      class="
                        btn-green
                        bg-lighter-green
                        rounded-circle
                        border-0
                        px-1
                      "
                      @click="modalSwotForm(3, 'โอกาส (Opportunities)', 'O')"
                      v-show="selectedTarget && allowEdit"
                    >
                      <eva-icon name="edit"></eva-icon>
                    </button>
                  </div>
                </div>
                <div class="col-6 border-bottom p-2">
                  <div class="text-yellow mb-3">อุปสรรค</div>
                  <div
                    class="mb-2"
                    v-for="swot in selectedTargetThreatSwot"
                    v-bind:key="swot.id"
                  >
                    <span class="font-weight-bold">{{ swot.badge }}:</span>
                    {{ swot.detail }}
                  </div>
                  <div class="text-right mt-2">
                    <button
                      class="
                        btn-yellow
                        bg-lighter-yellow
                        rounded-circle
                        border-0
                        px-1
                      "
                      @click="modalSwotForm(4, 'อุปสรรค (Threats)', 'T')"
                      v-show="selectedTarget && allowEdit"
                    >
                      <eva-icon name="edit"></eva-icon>
                    </button>
                  </div>
                </div>
                <div class="col-6 border-right p-2">
                  <div class="text-blue mb-3">จุดแข็ง</div>
                  <div
                    class="mb-2"
                    v-for="swot in selectedTargetStrengthSwot"
                    v-bind:key="swot.id"
                  >
                    <span class="font-weight-bold">{{ swot.badge }}:</span>
                    {{ swot.detail }}
                  </div>
                  <div class="text-right mt-2">
                    <button
                      class="
                        btn-blue
                        bg-lighter-blue
                        rounded-circle
                        border-0
                        px-1
                      "
                      @click="modalSwotForm(1, 'จุดแข็ง (Strengths)', 'S')"
                      v-show="selectedTarget && allowEdit"
                    >
                      <eva-icon name="edit"></eva-icon>
                    </button>
                  </div>
                </div>
                <div class="col-6 p-2">
                  <div class="text-red mb-3">จุดอ่อน</div>
                  <div
                    class="mb-2"
                    v-for="swot in selectedTargetWeaknessSwot"
                    v-bind:key="swot.id"
                  >
                    <span class="font-weight-bold">{{ swot.badge }}:</span>
                    {{ swot.detail }}
                  </div>
                  <div class="text-right mt-2">
                    <button
                      class="
                        btn-red
                        bg-lighter-red
                        rounded-circle
                        border-0
                        px-1
                      "
                      @click="modalSwotForm(2, 'จุดอ่อน (Weaknesses)', 'W')"
                      v-show="selectedTarget && allowEdit"
                    >
                      <eva-icon name="edit"></eva-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="box">
          <div class="box-header d-flex justify-content-between">
            <div class="text-ipepp">
              แนวทางการพัฒนา
              <div
                class="pointer text-blue"
                @click="showGuidingStrategyModal()"
              >
                <u>ดูคำแนะนำ</u>
              </div>
            </div>
            <button
              class="btn py-0 btn-red"
              @click="modalTargetStrategyForm(null)"
              v-show="
                selectedTarget &&
                selectedTarget.swots &&
                selectedTarget.swots.length &&
                allowEdit
              "
            >
              <eva-icon name="plus"></eva-icon>
              เพิ่มแนวทาง
            </button>
          </div>
          <div class="box-body pb-2 pr-0 pl-3 pt-1">
            <div
              class="text-ipepp pl-2 pr-4 pb-2 mb-1 border-bottom"
              v-for="target_strategy in targetStrategies"
              v-bind:key="target_strategy.id"
            >
              <div class="text-right">
                <button
                  v-show="allowEdit"
                  class="btn py-0"
                  @click="modalTargetStrategyForm(target_strategy)"
                >
                  <eva-icon name="edit"></eva-icon>
                </button>
              </div>
              <div class="mb-3 text-h5">{{ target_strategy.text }}</div>
              <div class="row">
                <div class="col-2">
                  <div class="text-gray">SWOT</div>
                </div>
                <div class="col-10">
                  <div
                    class="d-flex"
                    v-for="swot in target_strategy.swots"
                    v-bind:key="swot.id"
                  >
                    <swot-badge class="mt-1" :swot="swot"></swot-badge>
                    <div class="text-light-ipepp">
                      เหตุผล: {{ swot.pivot.cause }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modals-container></modals-container>
  </section>
</template>

<script>
import TargetStrategyForm from '@/components/TargetStrategyForm.vue';
import SwotForm from '@/components/SwotForm.vue';
import SwotBadge from '@/components/SwotBadge.vue';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingSwot from '@/components/guiding/GuidingSwot.vue';
import GuidingStrategy from '@/components/guiding/GuidingStrategy.vue';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {head, find, filter} from 'lodash';

export default {
  name: 'Swot',

  components: {
    SwotBadge,
  },

  data() {
    return {
      targetSelectedIndex: 0,
      targetSelectedID: null,
    };
  },

  created() {
    this.targetSelectedID = (head(this.targets) || []).id;
  },

  computed: {
    ...mapGetters(['latestNationalPlan']),
    ...mapState('peno', ['currentReport', 'allowEdit']),
    ...mapGetters('peno', ['currentTargets']),
    nationalPlan() {
      return this.latestNationalPlan;
    },
    targets() {
      return this.currentTargets;
    },
    selectedTarget() {
      return find(this.currentReport.targets, {
        id: this.targetSelectedID,
      });
    },
    targetStrategies() {
      if (!this.selectedTarget) return [];
      return this.selectedTarget.target_strategies;
    },
    selectedTargetStrengthSwot() {
      return this.filterSwot(1);
    },
    selectedTargetWeaknessSwot() {
      return this.filterSwot(2);
    },
    selectedTargetOpportunitySwot() {
      return this.filterSwot(3);
    },
    selectedTargetThreatSwot() {
      return this.filterSwot(4);
    },
  },

  methods: {
    filterSwot(type) {
      if (!this.selectedTarget) return [];
      return filter(this.selectedTarget.swots, {
        type: parseInt(type),
      });
    },
    selectTarget(index, targetID) {
      this.targetSelectedIndex = index;
      this.targetSelectedID = targetID;
    },
    showGuidingSwotModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingSwot,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    showGuidingStrategyModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingStrategy,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    modalSwotForm(type, title, label) {
      this.$modal.show(
        SwotForm,
        {
          name: 'swot_form',
          title: title,
          type: type,
          label: label,
          target: this.selectedTarget,
          swots: this.filterSwot(type),
        },
        {
          name: 'swot_form',
          pivotY: 0.02,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    modalTargetStrategyForm(targetStrategy = null) {
      this.$modal.show(
        TargetStrategyForm,
        {
          name: 'target_strategy_form',
          nationalStrategyID: this.strategySelectedID,
          target: this.selectedTarget,
          targetStrategy: targetStrategy,
        },
        {
          name: 'target_strategy_form',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.target-selected {
  border-right: 4px solid $red-color;
  background-color: $lighter-red-color;
}
</style>
